<template>
  <div v-if="authUserIsCoach">
    <!-- TODO: DEPRECATED -->
    <!-- <SubscriptionInfo></SubscriptionInfo> -->

    <div class="card my-3">
      <div class="card-body">
        <h5 class="card-title">Notifications</h5>
        <NotificationList :mode="'compact'" class="mt-3"></NotificationList>
      </div>
    </div>

    <div class="card my-3">
      <div class="card-body">
        <h5 class="card-title">Coaching</h5>
        <ClientRequestList class="mt-3"></ClientRequestList>
      </div>
      <div class="card-footer p-3 d-flex align-items-center">
        <div class="flex-grow-1 me-3">
          <IconButton
            @click="showClientInvitationModal = true"
            :icon="'user-plus'"
            :text="'Add a client'"
            class="btn btn-sm btn-outline-dark"
          ></IconButton>
        </div>
        <BootstrapDropdown>
          <li
            @click="showClientSentInvitationsModal = true"
            class="dropdown-item small"
            role="button"
          >
            <fa-icon :icon="['fas', 'address-book']" class="me-1"></fa-icon>
            View sent invitations
          </li>
        </BootstrapDropdown>
      </div>
    </div>

    <portal to="modal">
      <transition name="modal">
        <ClientInvitationModal
          @close="showClientInvitationModal = false"
          v-model="showClientInvitationModal"
          v-if="showClientInvitationModal"
        ></ClientInvitationModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <ClientSentInvitationsModal
          @close="showClientSentInvitationsModal = false"
          v-model="showClientSentInvitationsModal"
          v-if="showClientSentInvitationsModal"
        ></ClientSentInvitationsModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CoachSidebar',
  components: {
    // SubscriptionInfo: () => import('@/components/subscription/SubscriptionInfo'),
    NotificationList: () => import('@/controllers/notification/NotificationList'),
    IconButton: () => import('@/components/button/IconButton'),
    ClientRequestList: () => import('@/components/ClientRequestList'),
    ClientInvitationModal: () => import('@/components/modal/ClientInvitationModal'),
    ClientSentInvitationsModal: () => import('@/components/modal/ClientSentInvitationsModal'),
    BootstrapDropdown: () => import('@/components/bootstrap/BootstrapDropdown'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsCoach']),
    ...mapState('client', ['clientRequests', 'clientCount', 'clientQuota']),
  },
  methods: {
    async fetchCoachingRequests() {
      try {
        await this.$store.dispatch('client/fetchRequests');
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
  },
  data() {
    return {
      showClientInvitationModal: false,
      showClientSentInvitationsModal: false,
    };
  },
  mounted() {
    this.fetchCoachingRequests();
  },
  destroyed() {
    this.$store.dispatch('client/resetRequest');
  },
};
</script>
